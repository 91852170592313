<template>
  <div class="">
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto custom-circle-image"
      max-width="250"
      :height="height - 20"
      type="image"
    />
    <apexchart
      v-else
      type="donut"
      :height="height"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      defualt() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return "User Growth";
      },
    },
    items: {
      type: Object,
      default() {
        return {};
      },
    },
    height: {
      type: String,
      default() {
        return "270";
      },
    },
    noLabels: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    series() {
      const sr = [
        this.items?.on_peak_purchasing_price || 0,
        this.items?.off_peak_purchasing_price || 0,
      ];

      if (sr.length > 0) {
        return sr;
      }
      return [];
    },
    chartOptions() {
      return {
        chart: {
          width: 380,
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        colors: ["#FF9EB1", "#66D9E8"], // Pink, Light Blue, Yellow
        labels: ["Peak Hours", "Off-Peak Hours"],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          show: !this.noLabels, // Hides the legend
          // formatter: function (val, opts) {
          //   return val + " - " + opts.w.globals.series[opts.seriesIndex];
          // },
        },
        // title: {
        //   text: "Gradient Donut with custom Start-angle",
        // },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .custom-circle-image
  .v-skeleton-loader__image
    border-radius: 100% !important
</style>
